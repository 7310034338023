.new-activity-wrapper-fanfanle {
  padding: 16px;
  display: flex;
  background: #fff;

  .title {
    padding-top: 16px;
  }

  .card-half-width {
    width: calc(50% - 12px);
  }

  .ant-checkbox-wrapper {
    margin-right: 8px;
  }

  .ant-checkbox-wrapper+.ant-checkbox-wrapper {
    margin-left: 0;
  }

  .share-example {
    background: #eeeeee;
    padding: 16px;
    width: 394px;

    .example-title {
      line-height: 32px;
      text-align: center;
      font-weight: 500;
    }

    img {
      height: 100px;
    }
  }

  .task-example {
    background: #eeeeee;
    padding: 16px;
    width: 152px;

    .example-title {
      line-height: 32px;
      text-align: center;
      font-weight: 500;
    }

    img {
      height: 100px;
    }
  }

  .banner-example {
    background: #eeeeee;
    padding: 16px;
    width: 152px;

    .example-title {
      line-height: 32px;
      text-align: center;
      font-weight: 500;
    }

    img {
      height: 120px;
    }
  }

  .card-example {
    background: #eeeeee;
    padding: 16px;
    width: 140px;

    .example-title {
      line-height: 32px;
      text-align: center;
      font-weight: 500;
    }

    img {
      height: 120px;
    }
  }

  .uploader {
    .ant-form-item-children {
      display: flex;

      .uploader-tips {
        margin-left: 16px;
      }
    }
  }

  .ant-upload-picture-card-wrapper {
    width: auto;
    margin: 0;
  }

  .task-uploader {
    .ant-upload {
      width: 96px;
      height: 118px;
      overflow: hidden;
      margin: 0;
      border-radius: 5px;

      img {
        border: none;
      }
    }
  }

  .card-uploader {
    .ant-upload {
      width: 96px;
      height: 74px;
      overflow: hidden;
      margin: 0;
      border-radius: 5px;

      img {
        border: none;
      }
    }
  }

  .banner-uploader {
    .ant-upload {
      width: 96px;
      height: 109px;
      overflow: hidden;
      margin: 0;
      border-radius: 5px;

      img {
        border: none;
      }
    }
  }

  .qrcode-uploader {
    .ant-upload {
      width: 96px;
      height: 96px;
      overflow: hidden;
      margin: 0;
      border-radius: 5px;

      img {
        border: none;
      }
    }
  }

  .preview {
    margin: 16px 0;
    flex-basis: 280px;
    position: relative;

    .preview-img {
      margin: 0 auto;
      width: 203px;
      height: 420px;
      background: url('../../assets/preview-activity3.png') no-repeat left top;
      background-size: 100% 100%;
      position: relative;
      overflow: hidden;

      .preview-content {
        height: 406px;
        overflow: hidden;
        position: relative;
        border-radius: 34px;
      }

      .banner {
        position: absolute;
        width: 132px;
        height: 156px;
        top: 84px;
        left: 35px;
        border-radius: 10px;
        overflow: hidden;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .preview-title {
        width: 203px;
        position: absolute;
        top: 16px;
        left: 13px;
        text-align: center;
        font-size: 12px;
        color: white;
        width: 40px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }

      .banner-wrapper {
        white-space: nowrap;
        overflow-x: auto;
        overflow-y: hidden;
        height: 145px;
        width: 162px;
        position: absolute;
        top: 320px;
        left: 0;
        right: 0;
        margin: 0 auto;

        .banner-content {
          display: inline-block;
          width: 78px;
          height: 100%;
          margin-right: 6px;

          &:nth-last-child(1) {
            margin-right: 0;
          }

          img {
            margin: 4px auto 0;
            display: block;
            width: 90%;
            height: 80%;
          }

          .banner-content-bottom {
            position: absolute;
            bottom: 4px;
            display: flex;
            justify-content: space-between;

            div {
              color: white;
              font-size: 12px;
              transform: scale(0.8);
            }
          }
        }
      }
    }
  }
}