.new-activity-wrapper-funiu {
  padding: 16px;
  display: flex;
  background: #ffffff;

  .title {
    padding-top: 16px;
  }

  .card-half-width {
    width: calc(50% - 12px);
  }

  .ant-checkbox-wrapper {
    margin-right: 8px;
  }

  .ant-checkbox-wrapper + .ant-checkbox-wrapper {
    margin-left: 0;
  }

  .share-example {
    background: #eeeeee;
    padding: 16px;
    width: 267px;

    .example-title {
      line-height: 32px;
      text-align: center;
      font-weight: 500;
    }

    img {
      height: 78px;
    }
  }

  .task-example {
    background: #eeeeee;
    padding: 16px;
    width: 172px;

    .example-title {
      line-height: 32px;
      text-align: center;
      font-weight: 500;
    }

    img {
      height: 100px;
    }
  }

  .banner-example {
    background: #eeeeee;
    padding: 16px;
    width: 166px;

    .example-title {
      line-height: 32px;
      text-align: center;
      font-weight: 500;
    }

    img {
      height: 100px;
    }
  }

  .card-example {
    background: #eeeeee;
    padding: 16px;
    width: 170px;

    .example-title {
      line-height: 32px;
      text-align: center;
      font-weight: 500;
    }

    img {
      height: 100px;
    }
  }

  .uploader {
    .ant-form-item-control-input-content {
      display: flex;

      .uploader-tips {
        margin-left: 16px;
      }

    }
  }

  .ant-upload-picture-card-wrapper {
    width: auto;
    margin: 0;
  }

  .task-uploader {
    .ant-upload {
      width: 96px;
      height: 118px;
      overflow: hidden;
      margin: 0;
      border-radius: 5px;

      img {
        border: none;
      }
    }
  }

  .card-uploader {
    .ant-upload {
      width: 133px;
      height: 100px;
      overflow: hidden;
      margin: 0;
      border-radius: 5px;

      img {
        border: none;
      }
    }
  }

  .banner-uploader {
    .ant-upload {
      width: 156px;
      height: 100px;
      overflow: hidden;
      margin: 0;
      border-radius: 5px;

      img {
        border: none;
      }
    }
  }

  .qrcode-uploader {
    .ant-upload {
      width: 96px;
      height: 96px;
      overflow: hidden;
      margin: 0;
      border-radius: 5px;

      img {
        border: none;
      }
    }
  }

  .preview {
    margin: 16px 0;
    flex-basis: 280px;
    position: relative;

    .preview-img {
      margin: 0 auto;
      width: 203px;
      height: 702px;
      background: url('../../assets/funiu/画板1.png') no-repeat left top;
      background-size: 100% 100%;
      position: relative;
      overflow: hidden;

      .preview-content {
        height: 680px;
        overflow: hidden;
        position: relative;
      }

      .banner {
        position: absolute;
        width: 165px;
        height: 107px;
        top: 47px;
        left: 18px;
        border-radius: 8px;
        overflow: hidden;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .preview-title {
        display: none;
      }

      .banner-wrapper {
        white-space: nowrap;
        overflow-x: auto;
        overflow-y: hidden;
        height: 288px;
        width: 195px;
        position: absolute;
        top: 409px;
        left: 0;
        right: 0;
        margin: 0 auto;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        .banner-content {
          width: 93px;
          height: 144px;
          overflow: hidden;
          background-size: 100% 100%;

          img {
            margin: 4px auto 0;
            width: 93px;
          }

          .banner-content-bottom {
            display: none;
          }
        }
      }
    }
  }
}