.top-nav {
    display: flex;
    display: -webkit-flex; /* Safari */
    justify-content: space-between;
    flex-grow: 2;
    align-items: center;
    align-content: center;
    color: #ffffff;
    font-size: 14px;
}

.coins {
    vertical-align: middle;
}

.coins .charge-now {
    color: #ffffff;
    cursor: pointer;
}

.coins span {
    margin-left: 16px;
    display: inline-block;
    vertical-align: middle;
}

.coins .wechat {
    color: #ffffff;
}

.coins .wechat-name {
    color: #ffffff;
    margin-left: 0;
}