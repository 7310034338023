.home_today_tab .tody_tab_header {
  padding: 16px;
}

.home_today_tab .tody_tab_title {
  font-size: 19px;
  font-weight: 800;
}

.home_today_tab .tody_tab_lt, .home_today_tab .tody_tab_gt {
  cursor: pointer;
  padding: 0 20px;
}

.home_today_tab .tody_tab_lt:hover, .home_today_tab .tody_tab_gt:hover {
  color: skyblue;
}

.home_today_tab .tody_tab_main {
  padding: 0 16px 16px;
  height: 267px;
  display: flex;
  flex-direction: column;
}

.home_today_tab .tody_tab_main .tody_tab_main_box {
  display: flex;
  justify-content: space-around;
}

.home_today_tab .tody_tab_main .tody_tab_main_item {
  width: 120px;
  height: 120px;
  padding: 10px 10px 0;
  display: flex;
  flex-direction: column;
  background: #f2f2f2;
  text-align: center;
}

.home_today_tab .tody_tab_main .tody_tab_main_item.active {
  background: #0084ff;
  color: #fff;
}

.home_today_tab .tody_tab_main .tody_tab_main_item > span:nth-child(1) {
  height: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-weight: 700;
  color: #000;
}

.home_today_tab .tody_tab_main .tody_tab_main_item > span:nth-child(2) {
  height: 30px;
  line-height: 30px;
}

.home_today_tab .tody_tab_main .tody_tab_main_item_name {
  background: #fff;
}

.home_today_tab .tody_tab_main .tody_tab_main_item_text {
  padding: 16px;
}
