.activity_information {
    .site-page-header {
        margin: 20px 0;
    }
    .activity_information_flex {
        display: flex;
        >div.ant-form-item {
            margin: 0 1% 0 0;
        }
        .activity_information_flex_left {
            flex: 1;
        }
        
    }
}