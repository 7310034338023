.actions {
    color: #ebedf0
}

.actions span {
    color: #155BD4;
    margin-left: 4px;
    cursor: pointer;
    margin-right: 4px;
}

.actions span:first-of-type {
    margin-left: 0;
}

.actions span:last-of-type {
    margin-right: 0;
}

.newActivity {
    display: flex;
    display: -webkit-flex; /* Safari */
    justify-content: space-between;
    width: 100%;
    overflow: hidden;
    align-items: center;
    align-content: center;
}